import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getIsLoggedIn} from "modules/selectors/auth";
import {useGetParsedObject} from "modules/utils/helpers/dom";
import AppLoginError from "pages/AppLoginError";
import {loginAction, setMMIDAction} from "modules/actions";
import {ComingSoonPage} from "pages/ComingSoonPage";
import Cookies from "js-cookie";

// eslint-disable-next-line complexity
export const HOCAuth: React.FC = ({children}) => {
	const dispatch = useDispatch();
	const isLoggedIn = useSelector(getIsLoggedIn);
	const localHostMMID =
		window.location.host === "frontend.dev.loc:8080"
			? "A2ZMn01LM2JpJYsBQ2hI2Tkoj7DilebqWWhXQOdLO58gp/9PyVs1OuV3TyhpBIWj"
			: "";
	const query = useGetParsedObject();
	const mmid = (query?.mmid as string) || Cookies.get("mmid") || localHostMMID;
	const isSecretPassed = Cookies.get("passSecret");

	useEffect(() => {
		return () => {
			Cookies.remove("mmid");
		};
	}, []);

	if (process.env.REACT_APP_IS_COMING_SOON === "true" && !isSecretPassed) {
		return <ComingSoonPage />;
	}

	if (!mmid) {
		return <AppLoginError />;
	}

	dispatch(setMMIDAction(mmid));
	Cookies.set("mmid", mmid);

	if (!isLoggedIn) {
		dispatch(loginAction({mmid}));
		return null;
	}

	console.log(isLoggedIn);

	return <React.Fragment>{children}</React.Fragment>;
};
