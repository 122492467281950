import {SagaIterator} from "redux-saga";
import * as actions from "modules/actions";
import {takeLatest} from "typed-redux-saga";
import {loginSaga, registerSaga} from "modules/sagas/auth";
import {errorsManagerSaga} from "modules/sagas/errors";
import {fetchContestsSaga} from "modules/sagas/contests";
import {fetchStaticDataSaga, sendContactDataSaga} from "modules/sagas/static";
import {getDashboardSaga, saveAnswerSaga} from "modules/sagas/trivia";
import {fetchLeaderboardSaga} from "modules/sagas/leaderboard";
import {fetchSettingsDataSaga} from "modules/sagas/settings";
/**
 * Root saga that connect sagas with actions.
 */
export default function* rootSaga(): SagaIterator {
	/**
	 * JSONS
	 */
	yield* takeLatest(actions.fetchContests, fetchContestsSaga);
	yield* takeLatest(actions.fetchStaticData, fetchStaticDataSaga);
	yield* takeLatest(actions.fetchSettingsData, fetchSettingsDataSaga);
	/**
	 * Auth
	 */
	yield* takeLatest(actions.loginAction, loginSaga);
	yield* takeLatest(actions.registerAction, registerSaga);
	/**
	 * Trivia
	 */
	yield* takeLatest(actions.getUserDashboardAction, getDashboardSaga);
	yield* takeLatest(actions.saveAnswerAction, saveAnswerSaga);
	/**
	 * Other
	 */
	yield* takeLatest(actions.globalError, errorsManagerSaga);
	/**
	 * Help
	 */
	yield* takeLatest(actions.sendContactData, sendContactDataSaga);
	/**
	 * Leaderboard
	 */
	yield* takeLatest(actions.fetchLeaderboardAction, fetchLeaderboardSaga);
}
