import React, {useState} from "react";
import styled from "styled-components";
import welcomeBg from "assets/img/welcome-bg.jpg";
import logo from "assets/img/jersey-logo.svg";
import {BaseContainer} from "components/Layout";
import Cookies from "js-cookie";
const Wrapper = styled.div`
	display: flex;
	flex: 1;
	background-image: url(${welcomeBg});
	background-size: cover;
	flex-direction: column;
`;
const Logo = styled.img`
	max-width: 159px;
	width: 100%;
	margin: 0 auto;
	user-select: none;
`;
const GameTitle = styled.div`
	margin-top: 28px;
	font-family: Aerokids, sans-serif;
	color: #fff;
	font-size: 50px;
	margin-bottom: 15px;
	user-select: none;
`;
const Text = styled.div`
	color: #ffffff;
	font-family: Block, sans-serif;
	font-size: 40px;
	letter-spacing: 0.77px;
	line-height: 27px;
	text-align: center;
	text-transform: uppercase;
	user-select: none;
`;

export const ComingSoonPage: React.FC = () => {
	const [secretPassNum, setSecretPassNum] = useState(0);
	const clickHandler = () => {
		const counter = secretPassNum + 1;
		if (counter >= 10) {
			Cookies.set("passSecret", "true");
			window.location.reload();
		}
		setSecretPassNum(counter);
	};

	return (
		<Wrapper>
			<BaseContainer direction={"column"} align={"center"} justify={"center"}>
				<Logo src={logo} />
				<GameTitle>College Basketball Trivia</GameTitle>
				<Text onClick={clickHandler}>Coming Soon</Text>
			</BaseContainer>
		</Wrapper>
	);
};
