import React, {useEffect, useRef} from "react";
import {motion} from "framer-motion";
import styled from "styled-components";
import {MobileMenu} from "components/Menu/MobileMenu";
import {useLocation} from "react-router";
import {toggleBodyClass} from "modules/utils/helpers/dom";
import {useDimensions} from "modules/hooks/global";
import {Exist} from "components/Exist";
import {IconChevron} from "components/Icons";

const Wrapper = styled(motion.nav)<{isOpen: boolean}>`
	z-index: 10;
	height: calc(100vh - 80px);
	display: flex;
	flex-direction: column;
	width: 100%;
	top: 80px;
	position: absolute;
	padding-bottom: 20px;

	.background {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		background: #fff;
		z-index: 10;
		box-shadow: inset 0 0 40px -2px rgb(255 255 255 / 30%);
	}
`;

const BackText = styled.div`
	font-family: Block, sans-serif;
	font-size: 18px;
	margin-top: auto;
	position: relative;
	z-index: 2;
	display: flex;
	align-items: center;
	line-height: 10px;
	cursor: pointer;
	padding: 0 20px;
	span {
		display: inline-flex;
		margin-left: 5px;
		color: #da291c;
	}
	.chevron-left {
		transform: rotate(90deg);
		margin-right: 10px;
	}
`;

const navVariats = {
	open: {
		zIndex: 10,
	},
	closed: {
		zIndex: -1,
		transition: {
			delay: 0.8,
		},
	},
};

const sidebar = {
	open: (height: number) => {
		const webTransition = {
			type: "spring",
			stiffness: 15,
			restDelta: 2,
		};
		return {
			zIndex: 0,
			clipPath: `circle(${height * 2 + 200}px at 100% 48px)`,
			WebkitClipPath: `circle(${height * 2 + 200}px at 100% 48px)`,
			transition: webTransition,
		};
	},
	closed: {
		zIndex: 0,
		clipPath: "circle(0px at 86% 48px)",
		WebkitClipPath: "circle(0px at 86% 48px)",
		transition: {
			delay: 0.5,
			type: "spring",
			stiffness: 400,
			damping: 40,
		},
	},
};

interface IProps {
	isOpen: boolean;
	toggleCallback: () => void;
}

export const MobileMenuAnimated: React.FC<IProps> = ({isOpen, toggleCallback}) => {
	const {pathname} = useLocation();
	const containerRef = useRef(document.createElement("div"));
	const {height} = useDimensions(containerRef);

	const windowHeight = window.innerHeight;
	useEffect(() => {
		toggleBodyClass("fixed-scroll", isOpen);
	}, [isOpen]);

	useEffect(() => {
		if (isOpen) {
			toggleCallback();
		}
		// eslint-disable-next-line
	}, [pathname]);

	return (
		<Wrapper
			initial={false}
			animate={isOpen ? "open" : "closed"}
			custom={height}
			ref={containerRef}
			isOpen={isOpen}
			variants={navVariats}
			layout
			inherit={false}>
			<motion.div
				className={"background"}
				transformTemplate={() => `translateX(0) translateY(0) translateZ(0)`}
				variants={sidebar}
				custom={windowHeight}
			/>
			<MobileMenu isOpen={isOpen} />
			<Exist when={isOpen}>
				<BackText onClick={toggleCallback}>
					<IconChevron color={"#1B365D"} width={15} height={15} className={"chevron-left"} />
					Back To <span>my</span>Mike’s
				</BackText>
			</Exist>
		</Wrapper>
	);
};
