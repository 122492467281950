import {call, put} from "typed-redux-saga";
import {Api} from "modules/utils/Api";
import {fetchSettingsDataSuccess} from "modules/actions";

export const fetchSettingsDataSaga = function* () {
	try {
		const response = yield* call(Api.JSON.settings);
		yield put(
			fetchSettingsDataSuccess(response.settings)
		);
	} catch (e) {
		console.log(e);
	}
};
