import React from "react";
import styled, {css} from "styled-components";
import {NavLink} from "react-router-dom";
import {motion, AnimatePresence} from "framer-motion";
// import {BaseContainer} from "components/Layout";
// import {Exist} from "components/Exist";

const MenuList = styled(motion.div)`
	display: flex;
	flex-direction: column;
	padding: 0 20px;
	margin-top: 8px;
	position: absolute;
	left: 0;
	z-index: 10;
	width: 100%;
`;

const MenuItem = styled(motion.div)`
	border-bottom: 1px solid #1b365d;
	transform: translate(0, 50);
	opacity: 0;
`;

const LinkStyles = css`
	color: #1b365d;
	font-family: Edmond, sans-serif;
	text-decoration: none;
	height: 52px;
	display: flex;
	align-items: center;
	font-size: 15px;
	&.active {
		font-weight: bold;
		font-size: 16px;
	}
`;
const MenuLink = styled(NavLink)`
	${LinkStyles};
`;
// const MenuButton = styled.button`
// 	${LinkStyles};
// 	background: none;
// 	border: none;
// 	text-align: left;
// 	padding: 0;
// 	cursor: pointer;
// `;

const variants = {
	open: {
		transition: {staggerChildren: 0.07, delayChildren: 0.2},
	},
	closed: {
		transition: {staggerChildren: 0.05, staggerDirection: -1},
	},
};

const variantsItem = {
	open: {
		y: 0,
		opacity: 1,
		transition: {
			y: {stiffness: 1000, velocity: -100},
		},
	},
	closed: {
		y: 50,
		opacity: 0,
		transition: {
			y: {stiffness: 1000},
		},
	},
};

const Items = () => {
	return (
		<MenuList variants={variants}>
			<MenuItem variants={variantsItem} whileHover={{scale: 1.1}} whileTap={{scale: 0.95}}>
				<MenuLink exact={true} to={"/"}>
					Trivias
				</MenuLink>
			</MenuItem>
			<MenuItem variants={variantsItem} whileHover={{scale: 1.1}} whileTap={{scale: 0.95}}>
				<MenuLink to={"/leaderboard"}>Leaderboard</MenuLink>
			</MenuItem>
			<MenuItem variants={variantsItem} whileHover={{scale: 1.1}} whileTap={{scale: 0.95}}>
				<MenuLink to={"/prizes"}>Prizes</MenuLink>
			</MenuItem>
			<MenuItem variants={variantsItem} whileHover={{scale: 1.1}} whileTap={{scale: 0.95}}>
				<MenuLink to={"/help/rules"}>Rules</MenuLink>
			</MenuItem>
			<MenuItem variants={variantsItem} whileHover={{scale: 1.1}} whileTap={{scale: 0.95}}>
				<MenuLink to={"/help/faqs"}>FAQs</MenuLink>
			</MenuItem>
			<MenuItem variants={variantsItem} whileHover={{scale: 1.1}} whileTap={{scale: 0.95}}>
				<MenuLink to={"/help/terms"}>Terms & Conditions</MenuLink>
			</MenuItem>
			<MenuItem variants={variantsItem} whileHover={{scale: 1.1}} whileTap={{scale: 0.95}}>
				<MenuLink to={"/help/contacts"}>Contact Us</MenuLink>
			</MenuItem>
		</MenuList>
	);
};

interface IProps {
	isOpen: boolean;
}

export const MobileMenu: React.FC<IProps> = ({isOpen}) => {
	return (
		<AnimatePresence initial={true}>
			{isOpen ? (
				<motion.div
					exit={{
						display: "none",
						transition: {delay: 1},
					}}>
					<Items />
				</motion.div>
			) : null}
		</AnimatePresence>
	);
};
