// Next polyfills are required to made IE11 work.
import "core-js/es";
import "core-js/features/url";
import "core-js/features/url-search-params";

import "assets/css/reset.css";
import "assets/css/fonts.css";
import "assets/css/core.css";

import React, {ComponentType, lazy, Suspense} from "react";
import ReactDOM from "react-dom";
import {BrowserRouter as Router, Route, RouteComponentProps, Switch} from "react-router-dom";
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import {Provider} from "react-redux";
import {store} from "modules/store";
import {AnimatePresence} from "framer-motion";
import {Preloader} from "components/Preloader";
import {GlobalPreLoader} from "components/GlobalPreLoader";
import {FlexGrowContainer} from "components/Layout";
import {HOCAuth} from "components/HOCAuth";
import GlobalErrors from "components/GlobalErrors";
import {NotRegisteredRoute, PrivateRoute} from "components/Routes";
import {FetchGame} from "components/FetchGame";

type Factory<T> = () => Promise<{default: ComponentType<T>}>;

export function retryFailLoad<T>(fn: Factory<T>, retriesLeft = 5, interval = 1000): Factory<T> {
	return () =>
		new Promise((resolve, reject) => {
			fn()
				.then(resolve)
				.catch((error: unknown) => {
					setTimeout(() => {
						if (retriesLeft === 1) {
							window.location.reload();
							reject(error);
							return;
						}
						retryFailLoad(fn, retriesLeft - 1, interval)().then(resolve, reject);
					}, interval);
				});
		});
}

const AppLoginError = lazy(retryFailLoad(() => import("./pages/AppLoginError")));
const WelcomePage = lazy(retryFailLoad(() => import("./pages/WelcomePage")));
const HomePage = lazy(retryFailLoad(() => import("./pages/HomePage")));
const TriviaPage = lazy(retryFailLoad(() => import("./pages/TriviaPage")));
const LeaderboardPage = lazy(retryFailLoad(() => import("pages/LeaderboardPage")));
const HelpPage = lazy(retryFailLoad(() => import("./pages/HelpPage")));
const PrizePage = lazy(retryFailLoad(() => import("./pages/PrizePage")));


let errorCount = 0;
const MAX_ERRORS = 100;
const ignoreErrors = [
	'ResizeObserver loop limit exceeded',
	'Invalid MMID',
	'Authentication Required',
	'Invalid credentials',
];
Sentry.init({
	dsn: "https://38de502b11674db8b95b75b5c7f5dab0@o151969.ingest.sentry.io/5874603",
	integrations: [
		new Integrations.BrowserTracing(),
		new Sentry.Integrations.Breadcrumbs({
			console: false,
		}),
	],
	environment: process.env.REACT_APP_SENTRY_ENV || "development",
	allowUrls: [".fanhubmedia.com", "tailgatetrivia.jerseymikes.com"],
	denyUrls: [
		"quantcast",
		"xsca",
		// browser's extensions
		/extensions\//i,
		/^chrome:\/\//i,
		/^moz-extension:\/\//i,
	],
	ignoreErrors,
	beforeSend: (event: Sentry.Event) => {
		errorCount++;
		if (errorCount > MAX_ERRORS) {
			return null;
		}
		if (event && event.message && ignoreErrors.includes(event.message)){
			return null
		}
		return event;
	},
	sampleRate: 0.1,
});

const App: React.FC = () => (
	<Provider store={store}>
		<Router basename={"/"}>
			<HOCAuth>
				<FetchGame />
				<FlexGrowContainer>
					<Route
						render={({location}: RouteComponentProps) => (
							<React.Fragment>
								<Suspense fallback={<Preloader withCoverBg={true} position={"center"} />}>
									<AnimatePresence exitBeforeEnter={true} initial={false}>
										<Switch location={location} key={location.pathname}>
											<PrivateRoute exact={true} path={"/"} component={HomePage} />
											<PrivateRoute exact={true} path={"/trivia/:id"} component={TriviaPage} />
											<PrivateRoute
												exact={true}
												path={"/leaderboard"}
												component={LeaderboardPage}
											/>
											<NotRegisteredRoute
												exact={true}
												path={"/welcome"}
												component={WelcomePage}
											/>
											<Route exact={true} path={"/help/:section?"} component={HelpPage} />
											<Route exact={true} path={"/prizes"} component={PrizePage} />
											<Route path={"*"} component={AppLoginError} />
										</Switch>
									</AnimatePresence>
								</Suspense>
							</React.Fragment>
						)}
					/>
				</FlexGrowContainer>
			</HOCAuth>
		</Router>
		<GlobalErrors />
		<GlobalPreLoader />
	</Provider>
);
ReactDOM.render(<App />, document.getElementById("root"));
