import * as React from "react";
import {IIconProps} from "modules/types";

export const IconOval: React.FC<IIconProps> = ({width = "60px", height = "60px", color, className}) => (
	<svg
		className={className}
		width={width}
		height={height}
		viewBox="0 0 60 60"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink">
		<title>Oval</title>
		<defs>
			<linearGradient x1="50%" y1="50%" x2="100%" y2="50%" id="linearGradient-1">
				<stop stopColor="#FBFBFB" offset="0%" />
				<stop stopColor={color} offset="100%" />
			</linearGradient>
		</defs>
		<g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g
				id="Modals-/-Misc-/-Timer-/-Circle"
				transform="translate(-15.000000, 0.000000)"
				stroke="url(#linearGradient-1)"
				strokeWidth="3">
				<circle id="Oval" cx="45" cy="30" r="28" />
			</g>
		</g>
	</svg>
);
