import React from "react";
import {Route, RouteProps, Redirect} from "react-router-dom";
import {useSelector} from "react-redux";
import {getIsRegistered} from "modules/selectors/auth";
// import {useSelector} from "react-redux";
// import {getIsUserAuthed} from "modules/selectors/user";
// import {get} from "lodash";

export const PrivateRoute: React.FC<RouteProps> = (props) => {
	const isRegistered = useSelector(getIsRegistered);
	return isRegistered ? <Route {...props} /> : <Redirect to={"/welcome"} />;
};

export const NotRegisteredRoute: React.FC<RouteProps> = (props) => {
	const isRegistered = useSelector(getIsRegistered);
	// window.scrollTo(0, 0);
	return isRegistered ? <Redirect to={"/"} /> : <Route {...props} />;
};
