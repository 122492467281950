import React from "react";
import styled, {CSSObject, keyframes} from "styled-components";
import {media} from "assets/css/media";
import {IconTimes} from "components/Icons/IconTimes";

const ModalWrapper = styled.div<{zIndex: number}>`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.5);
	z-index: ${(props) => props.zIndex};
	display: flex;
	justify-content: center;
	align-items: center;
`;

const fadeIn = keyframes`
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
`;

const ModalInner = styled.div`
	min-width: 600px;
	max-width: 100%;
	background: #ffffff;
	position: relative;
	border-radius: 4px;
	box-sizing: border-box;
	padding: 52px 38px;
	animation: ${fadeIn} 0.5s linear;
	overflow: auto;
	max-height: 90%;
	@media (max-width: ${media.mobile}) {
		width: 90%;
		max-width: 100%;
		min-width: auto;
		padding: 35px 24px;
		max-height: 93%;
		overflow: auto;
	}
`;

const CrossWrapper = styled.div`
	width: 46px;
	height: 46px;
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	cursor: pointer;
	margin-left: auto;
	align-items: center;
	justify-content: center;

	img {
		width: 20px;
		margin: 5px;
	}
`;

interface IProps {
	children?: React.ReactNode;
	onClose?: () => null | void;
	hide_close?: boolean;
	modalStyles?: CSSObject;
	zIndex?: number;
}

export const Modal: React.FC<IProps> = ({
	children = null,
	onClose,
	hide_close,
	modalStyles,
	zIndex = 10,
	...rest
}: IProps) => (
	<ModalWrapper {...rest} zIndex={zIndex}>
		<ModalInner style={modalStyles}>
			{!hide_close && (
				<CrossWrapper onClick={onClose}>
					<IconTimes width={20} height={20} color={"#232323"} />
				</CrossWrapper>
			)}
			{children}
		</ModalInner>
	</ModalWrapper>
);
