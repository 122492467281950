import {createReducer} from "redux-act";
import {IGlobalReducer} from "modules/types";
import {setGlobalPreloaderState} from "modules/actions";

const defaultState: IGlobalReducer = {
	isShowGlobalPreloader: false,
};
export const global = createReducer<IGlobalReducer>({}, defaultState);
global.on(setGlobalPreloaderState, (state, payload) => ({
	...state,
	isShowGlobalPreloader: payload,
}));
