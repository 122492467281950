import {createReducer} from "redux-act";
import {ITriviaReducer} from "modules/types/trivia";
import {cleanUpTrivia, getUserDashboardActionSuccess, setAnswersAction, setIsFirstTimePlay} from "modules/actions";

const defaultState: ITriviaReducer = {
	dashboard: {
		isPointsEarned: false,
		contestCount: 0,
		scores: {},
	},
	answers: [],
	allAnswers: {},
	isFirstTimePlay: false
};
export const trivia = createReducer<ITriviaReducer>({}, defaultState);
trivia.on(getUserDashboardActionSuccess, (state, payload) => ({
	...state,
	dashboard: payload,
}));
trivia.on(setAnswersAction, (state, payload) => ({
	...state,
	answers: payload.answers,
	allAnswers: {
		...state.allAnswers,
		...payload.answer,
	},
}));
trivia.on(cleanUpTrivia, (state) => ({
	...state,
	answers: [],
	allAnswers: {},
}));
trivia.on(setIsFirstTimePlay, (state, payload) => ({
	...state,
	isFirstTimePlay: payload
}))