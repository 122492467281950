import {useLocation} from "react-router";
import * as qs from "qs";
import {IDictionary} from "modules/types";

export const toggleBodyClass = (className: string, state: boolean) => {
	if (state) {
		document.body.classList.add(className);
	} else {
		document.body.classList.remove(className);
	}
};

export const routeLayoutsAnimations = {
	initial: {
		opacity: 0,
	},
	in: {
		opacity: 1,
	},
	out: {
		opacity: 0,
	},
};

export const useQuery = () => {
	return new URLSearchParams(useLocation().search);
};

export const useGetParsedObject = (): IDictionary<unknown> => {
	const {search} = useLocation();
	// eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
	return qs.parse(search, {
		ignoreQueryPrefix: true,
		decoder: (e: string) => window.decodeURIComponent(e),
	});
};

export function scrollTop() {
	window.scrollTo(0, 0);
}
