import {call, put} from "typed-redux-saga";
import {Api} from "modules/utils/Api";
import {fetchContestsSuccess} from "modules/actions";

export const fetchContestsSaga = function* () {
	try {
		const contests = yield* call(Api.JSON.contests);
		yield put(fetchContestsSuccess(contests));
	} catch (e) {
		console.log(e);
	}
};
