import * as React from "react";
import {IIconProps} from "modules/types";

export const IconFacebook: React.FC<IIconProps> = ({title, width = 12, height = 12, color}) => (
	<svg width={`${width}`} height={`${height}`} viewBox="0 0 30 30">
		<title>{title}</title>
		<path
			fill={color}
			fillRule="evenodd"
			d="M15 0c8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15C0 6.716 6.716 0 15 0zm0 2C7.82 2 2 7.82 2 15s5.82 13 13 13 13-5.82 13-13S22.18 2 15 2zm2.703 4.005L17.383 6c-2.607 0-4.333 1.554-4.412 4.372l-.002.204v2.591H10v3.4h2.969v7.438h3.651v-7.438h2.724l.518-3.4H16.62V10.96l.002-.122c.038-.852.479-1.65 1.745-1.712l.158-.003H20V6.23l-.394-.06-.435-.055-.398-.042-.441-.037-.31-.019-.319-.012z"
			clipRule="evenodd"
		/>
	</svg>
);

export const IconShareFacebook: React.FC<IIconProps> = ({width = 24, height = 24, color}) => (
	<svg width={width} height={height} viewBox="0 0 24 24">
		<path
			d="M14.8624,12 L15.1834,9.667 L12.7194,9.667 L12.7194,8.536 C12.7194,7.816 12.9194,7.325 13.9534,7.325 L15.2694,7.324 L15.2694,5.098 C15.0424,5.068 14.2604,5 13.3494,5 C11.4494,5 10.1494,6.16 10.1494,8.29 L10.1494,9.667 L8.0004,9.667 L8.0004,12 L10.1494,12 L10.1494,19 L12.7194,19 L12.7194,12 L14.8624,12"
			fill={color}
		/>
	</svg>
);

export const IconShare: React.FC<IIconProps> = ({width = 40, height = 40, color = "#AEC2DD", onClick}) => (
	<svg width={width} height={height} viewBox="0 0 40 40" onClick={onClick}>
		<rect width="40" height="40" rx="20" fill={color}/>
		<path d="M26 24.08C25.24 24.08 24.56 24.38 24.04 24.85L16.91 20.7C16.96 20.47 17 20.24 17 20C17 19.76 16.96 19.53 16.91 19.3L23.96 15.19C24.5 15.69 25.21 16 26 16C27.66 16 29 14.66 29 13C29 11.34 27.66 10 26 10C24.34 10 23 11.34 23 13C23 13.24 23.04 13.47 23.09 13.7L16.04 17.81C15.5 17.31 14.79 17 14 17C12.34 17 11 18.34 11 20C11 21.66 12.34 23 14 23C14.79 23 15.5 22.69 16.04 22.19L23.16 26.35C23.11 26.56 23.08 26.78 23.08 27C23.08 28.61 24.39 29.92 26 29.92C27.61 29.92 28.92 28.61 28.92 27C28.92 25.39 27.61 24.08 26 24.08Z" fill="#1B365D"/>
	</svg>
);
