import styled from "styled-components";
import {motion} from "framer-motion";
import React from "react";
import {Header} from "components/Header";
import {Footer} from "components/Footer";

interface IBaseContainer {
	align?: string;
	justify?: string;
	direction?: string;
	overFlow?: string;
	alignSelf?: string;
	padding?: string;
	mobilePadding?: string;
}

export const BaseContainer = styled(motion.div)<IBaseContainer>`
	width: 100%;
	max-width: 1180px;
	padding: ${(props) => props.padding || "0 20px"};
	margin: 0 auto;
	display: flex;
	align-self: ${(props) => props.alignSelf || "initial"};
	align-items: ${(props) => props.align || "flex-start"};
	justify-content: ${(props) => props.justify || "flex-start"};
	flex-direction: ${(props) => props.direction || "row"};
	overflow: ${(props) => props.overFlow || "initial"};
	position: relative;
	flex: 1;
`;

export const FlexGrowContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
`;

interface IHOCFlexGrowContainer {
	withBanners?: boolean;
}

export const HOCWithBannersContainer: React.FC<IHOCFlexGrowContainer> = ({children}) => {
	return (
		<React.Fragment>
			{children}
		</React.Fragment>
	);
};

export const HOCWithHeader: React.FC = ({children}) => (
	<React.Fragment>
		<Header />
		{children}
		<Footer />
	</React.Fragment>
);
