import {once, partialRight} from "lodash";
import {ConnextraType, createConnextraScriptTag} from "modules/utils/connextra";
import {MutableRefObject, useCallback, useEffect, useRef, useState} from "react";

export const useDimensions = (ref: MutableRefObject<HTMLDivElement>) => {
	const dimensions = useRef({width: 0, height: 0});

	useEffect(() => {
		dimensions.current.width = ref.current.offsetWidth;
		dimensions.current.height = ref.current.offsetHeight;
	}, [ref]);

	return dimensions.current;
};

export const useMediaQuery = (query: string) => {
	const mediaMatch = window.matchMedia(query);
	const [matches, setMatches] = useState(mediaMatch.matches);

	useEffect(() => {
		const handler = (e: MediaQueryListEvent) => setMatches(e.matches);
		if ("addListener" in mediaMatch) {
			mediaMatch.addListener(handler);
		} else {
			mediaMatch.addEventListener("change", handler);
		}

		return () => {
			if ("addListener" in mediaMatch) {
				mediaMatch.removeListener(handler);
			} else {
				mediaMatch.removeEventListener("change", handler);
			}
		};
	});

	return matches;
};

export const useConnextra = (type?: ConnextraType) => {
	useEffect(() => {
		if (type) {
			createConnextraScriptTag(type);
		}
	}, [type]);

	/* eslint-disable-next-line @typescript-eslint/unbound-method */
	const trackOnce = useCallback(() => {
		once(partialRight(createConnextraScriptTag, null));
	}, []);

	return {
		trackOnce,
	};
};
