import React from "react";
import styled from "styled-components";
import {motion, SVGMotionProps} from "framer-motion";

const MenuToggleIcon = styled(motion.div)`
	z-index: 11;
	position: relative;
	cursor: pointer;
	margin-left: auto;
	display: flex;
	align-items: center;
`;

const Path = (props: SVGMotionProps<SVGElement>) => (
	<motion.path fill="transparent" strokeWidth="3" stroke="white" strokeLinecap="round" {...props} />
);

interface IProps {
	toggleCallback: () => void;
	isOpen: boolean;
}

export const MenuToggle: React.FC<IProps> = ({isOpen, toggleCallback}) => {
	return (
		<MenuToggleIcon aria-haspopup="true" aria-label="Hamburger Menu" role="button" onClick={() => toggleCallback()} animate={isOpen ? "open" : "closed"}>
			<svg width="20" height="20" viewBox="0 0 23 23">
				<Path
					variants={{
						closed: {d: "M 2 2.5 L 20 2.5", stroke: "#253746"},
						open: {
							d: "M 3 16.5 L 17 2.5",
							stroke: "#253746",
						},
					}}
				/>
				<Path
					d="M 2 9.423 L 20 9.423"
					variants={{
						closed: {opacity: 1, stroke: "#253746"},
						open: {opacity: 0, stroke: "#253746"},
					}}
					transition={{duration: 0.1}}
				/>
				<Path
					variants={{
						closed: {d: "M 2 16.346 L 20 16.346", stroke: "#253746"},
						open: {d: "M 3 2.5 L 17 16.346", stroke: "#253746"},
					}}
				/>
			</svg>
		</MenuToggleIcon>
	);
};
