import {createAction} from "redux-act";
import {IAnswer, IDashboard, ISavedAnswer} from "modules/types/trivia";
import {IDictionary} from "modules/types";

export const getUserDashboardAction = createAction();
export const getUserDashboardActionSuccess = createAction<IDashboard>();

export const saveAnswerAction = createAction<IAnswer>();
export const setAnswersAction =
	createAction<{
		answers: ISavedAnswer[];
		answer: IDictionary<number>;
	}>();

export const cleanUpTrivia = createAction();

export const setIsFirstTimePlay = createAction<boolean>();
