export enum ConnextraType {
	HOME = "homepage",
	TRIVIA = "triva",
	LEADERBOARD = "leaderboard",
	SHARE = "shareclick",
	SHARE_FB = "shareclick_facebook",
	SHARE_TW = "shareclick_twitter",
	HELP_PAGE = "helppage",
	FAQ_PAGE = "faqpage",
	WELCOME_PAGE = "welcomepage",
	PRIZE_PAGE = "prizepage",
}

const CONNEXTRA_URL = `https://us.connextra.com/dcs/tagController/tag/0a771c36be05/jerseymikes`;
const IS_ENABLED = process.env.REACT_APP_ENABLE_CONNEXTRA === "true";

export const createConnextraScriptTag = (type: ConnextraType) => {
	if (!IS_ENABLED) {
		return;
	}
	const elementName = "script";
	const SCRIPT_ID = "connextra-tagging";
	document.getElementById(SCRIPT_ID)?.remove();
	const src = `${CONNEXTRA_URL}/${type}`;

	const url = new URL(src);

	const script = document.createElement(elementName);
	script.id = SCRIPT_ID;
	script.src = url.toString();
	script.async = true;
	script.defer = true;

	const firstJS = document.getElementsByTagName(elementName)[0];
	firstJS.parentNode!.insertBefore(script, firstJS);

	return script;
};
