import React from "react";
import styled from "styled-components";
import {BaseContainer} from "components/Layout";
import headerLogo from "assets/img/header-logo.png";
import {MenuToggle} from "components/Menu/MobileToggle";
import {useCycle} from "framer-motion";
import {MobileMenuAnimated} from "components/Menu/MobileMenuAnimated";
import headerBg from "assets/img/header.jpg";
import {useLocation} from "react-router";
import {useSelector} from "react-redux";
import {getIsRegistered} from "modules/selectors/auth";
import {Exist} from "components/Exist";
import {useHistory} from "react-router-dom";

const HeaderWrapper: React.FC = styled.header`
	box-shadow: 0 3px 13px 0 rgba(0, 0, 0, 0.07);
	background: url(${headerBg});
	height: 80px;
	display: flex;
`;
const LogoLink = styled.div`
	text-decoration: none;
	display: flex;
	align-items: center;

	a {
		display: inline-flex;
		align-items: center;
		justify-content: center;
	}
`;
const Logo = styled.img`
	height: 56px;
`;

export const Header: React.FC = () => {
	const [isOpen, toggleOpen] = useCycle(false, true);
	const isRegister = useSelector(getIsRegistered);
	const history = useHistory();
	const location = useLocation();

	const isShowCross = location.pathname.split("/").includes("help") && !isRegister;
	const backToWelcome = () => {
		history.push("/");
	};
	return (
		<React.Fragment>
			<HeaderWrapper>
				<BaseContainer align={"center"}>
					<LogoLink>
						<a href="/">
							<Logo src={headerLogo} />
						</a>
					</LogoLink>
					<Exist when={isShowCross}>
						<MenuToggle toggleCallback={backToWelcome} isOpen={true} />
					</Exist>
					<Exist when={!isShowCross}>
						<MenuToggle toggleCallback={toggleOpen} isOpen={isOpen} />
					</Exist>
				</BaseContainer>
			</HeaderWrapper>
			<MobileMenuAnimated isOpen={isOpen} toggleCallback={toggleOpen} />
		</React.Fragment>
	);
};
