import {HTTPClient as HTTPClientCore, IRequestConfig} from "@fanhubmedia/fe-common-utils";
import axios from "axios";
import {CANCEL} from "redux-saga";
import {IApiResponse, ILoginAPIBody, IRegisterAPIBody, IUser} from "modules/types";
import ApiError from "modules/utils/Api/ApiError";
import {IContest} from "modules/types/contests";
import {IStaticData, IFaqItem, ISendContactData, ISettings} from "modules/types/static";
import {IAnswer, IDashboard, ISavedAnswer} from "modules/types/trivia";
import {ILeaderboard} from "modules/types/leaderboard";
// import {IDictionary} from "modules/types";
// import {
// 	IApiResponse,
// } from "modules/types/api";
// import ApiError from "modules/utils/Api/ApiError";

interface INewConfig extends IRequestConfig {
	params: unknown;
}

export class HTTPClient extends HTTPClientCore {
	/**
	 * Overridden method adds CancelToken symbol, that allow redux-saga'
	 * "takeLatest" function to cancel any requests automatically.
	 */
	public makeRequest<T = never>(config: IRequestConfig): Promise<T> {
		const source = axios.CancelToken.source();

		const new_config: INewConfig = {
			...config,
			params: {
				...config.params,
				// sid: 'ef740d410c09396157aa6462_1617695285'
			},
			cancelToken: source.token,
		};

		const request = super.makeRequest(new_config);

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		request[CANCEL] = () => source.cancel();

		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return request;
	}
}

const APIClient = new HTTPClient({
	baseURL: process.env.REACT_APP_API_URL || "",
	withCredentials: true,
	onCatchNetworkError: (err) => {
		const responseData = err?.response?.data as IApiResponse;
		ApiError.CHECK(responseData, err?.response?.status);
	},
});

const JSONClient = new HTTPClient({
	baseURL: process.env.REACT_APP_JSON_URL || "",
});

export const Api = {
	JSON: {
		contests: () => JSONClient.get<IContest[]>("contests.json"),
		static: () => JSONClient.get<IStaticData>("help_pages.json"),
		faqs: () => JSONClient.get<IFaqItem[]>("faqs.json"),
		settings: () => JSONClient.get<{settings: ISettings}>("settings.json"),
	},
	Auth: {
		login: (params: ILoginAPIBody) => APIClient.post<IApiResponse<{user: IUser}>>("auth/login", params),
		register: (params: IRegisterAPIBody) => APIClient.post<IApiResponse<{user: IUser}>>("auth/register", params),
	},
	User: {
		dashboard: () => APIClient.get<IApiResponse<{dashboard: IDashboard}>>("/user/dashboard"),
	},
	Trivia: {
		answer: (params: IAnswer) =>
			APIClient.post<
				IApiResponse<{
					stats: {
						answers: ISavedAnswer[];
						isFirstTimePlay: boolean;
						earnedError: string;
					};
				}>
			>("answer/save", params),
	},
	Help: {
		contact: (params: ISendContactData) => APIClient.post("contact", params),
	},
	Leaderboard: {
		get: () => APIClient.get<IApiResponse<{leaderboard: {schools: ILeaderboard[]}}>>("/school/leaderboard"),
	},
};

export * from "modules/utils/Api/ApiError";
