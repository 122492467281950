import {SagaIterator} from "redux-saga";
import {call, put, select} from "typed-redux-saga";
import {
	getUserDashboardActionSuccess,
	globalError,
	setAnswersAction,
	setGlobalPreloaderState,
	setIsFirstTimePlay,
} from "modules/actions";
import {Api} from "modules/utils/Api";
import {logSagaError} from "modules/utils/helpers/common";
import {ISagaAction} from "modules/types";
import {IAnswer} from "modules/types/trivia";
import {getMMID} from "modules/selectors/auth";

export const getDashboardSaga = function* (): SagaIterator {
	const mmid = yield* select(getMMID);
	try {
		yield* put(setGlobalPreloaderState(true));
		const {success} = yield* call(Api.User.dashboard);
		yield* put(getUserDashboardActionSuccess(success.dashboard));
	} catch (e) {
		logSagaError("getDashboardSaga", "none", e, {mmid});
		yield* put(globalError(e));
	}
	yield* put(setGlobalPreloaderState(false));
};

export const saveAnswerSaga = function* ({payload}: ISagaAction<IAnswer>): SagaIterator {
	const mmid = yield* select(getMMID);
	try {
		yield* put(setGlobalPreloaderState(true));
		const {success} = yield* call(Api.Trivia.answer, payload);
		yield* put(
			setAnswersAction({
				answers: success.stats.answers,
				answer: {[payload.question]: payload.option},
			})
		);

		const {earnedError, isFirstTimePlay} = success.stats;
		yield* put(
			setIsFirstTimePlay(isFirstTimePlay && earnedError === '')
		);

	} catch (e) {
		logSagaError("saveAnswerSaga", "none", e, {mmid});
		yield* put(globalError(e));
	}
	yield* put(setGlobalPreloaderState(false));
};
