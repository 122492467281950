import {createReducer} from "redux-act";
import {IUserReducer} from "modules/types";
import {loginActionSuccess} from "modules/actions";

const defaultState: IUserReducer = {
	user: {
		id: 0,
		isBackdoor: false,
		dashboard: {
			contestCount: 0,
			isPointsEarned: false,
			scores: [],
		},
	},
};
export const user = createReducer<IUserReducer>({}, defaultState);
user.on(loginActionSuccess, (state, payload) => ({
	...state,
	user: payload,
}));
