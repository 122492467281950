import {createReducer} from "redux-act";
import {fetchStaticDataSuccess, sendContactDataSuccess, clearContactDataSuccess} from "modules/actions/static";
import {IStaticData} from "modules/types/static";

const initialState: IStaticData = {
	rules: {
		title: "",
		text: "",
	},
	faq: [],
	terms: {
		title: "",
		text: "",
	},
	contact: {
		title: "",
		text: "",
	},
	contact_data_sent: false,
};

export const static_content = createReducer<IStaticData>({}, initialState);
static_content.on(fetchStaticDataSuccess, (state, payload) => ({
	...state,
	...payload,
}));
static_content.on(sendContactDataSuccess, (state) => ({
	...state,
	contact_data_sent: true,
}));
static_content.on(clearContactDataSuccess, (state) => ({
	...state,
	contact_data_sent: false,
}));
