import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	color: #000;
	font-size: 20px;
	flex: 1;
	font-weight: bold;
`;

const AppLoginError: React.FC = () => {
	return <Wrapper>App login error</Wrapper>;
};

export default AppLoginError;
