import {createReducer} from "redux-act";
import {IAuthReducer} from "modules/types";
import {loginActionSuccess, setIsLoggedInAction, setIsRegisteredAction, setMMIDAction} from "modules/actions";

const defaultState: IAuthReducer = {
	isLoggedIn: false,
	isRegistered: true,
	mmid: "",
};
export const auth = createReducer<IAuthReducer>({}, defaultState);
auth.on(loginActionSuccess, (state) => ({...state, isLoggedIn: true}));
auth.on(setIsLoggedInAction, (state, payload) => ({...state, isLoggedIn: payload}));
auth.on(setIsRegisteredAction, (state, payload) => ({...state, isRegistered: payload}));
auth.on(setMMIDAction, (state, payload) => ({...state, mmid: payload}));
