import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {fetchContests} from "modules/actions";

export const FetchGame: React.FC = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(fetchContests());
	}, [dispatch]);
	return null;
};
