import * as React from "react";
import {IIconProps} from "modules/types";

export const IconTick: React.FC<IIconProps> = ({title, width, height, color, onClick, className}) => (
	<svg
		width={`${width as number}px`}
		height={`${height as number}px`}
		viewBox="0 0 30 22"
		onClick={onClick}
		className={className}>
		<title>{title}</title>
		<g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="3.0-Icons-/-Tick" transform="translate(0.000000, -4.000000)" fill={color} fillRule="nonzero">
				<path
					d="M27.9232204,4.3560593 L9.30372768,22.975552 L2.0474825,15.7193068 C1.56777529,15.272311 0.820235986,15.2855004 0.356595883,15.7491405 C-0.10704422,16.2127806 -0.120233678,16.9603199 0.326762162,17.4400271 L8.44336752,25.5566325 C8.91870441,26.0313782 9.68875096,26.0313782 10.1640879,25.5566325 L29.6439407,6.07677963 C30.1186864,5.60144274 30.1186864,4.83139619 29.6439407,4.3560593 C29.1686038,3.88131357 28.3985573,3.88131357 27.9232204,4.3560593 Z"
					id="tick"
				/>
			</g>
		</g>
	</svg>
);

IconTick.defaultProps = {
	title: "Tick",
	width: 13,
	height: 9,
	color: "#000",
};
