import {createReducer} from "redux-act";
import {fetchSettingsDataSuccess} from "modules/actions";
import {ISettings} from "modules/types/static";

const initialState: ISettings = {
	prizeImage: "",
};

export const settings = createReducer<ISettings>({}, initialState);
settings.on(fetchSettingsDataSuccess, (state, payload) => ({
	...state,
	prizeImage: payload.prizeImage,
}));
