import {createAction} from "redux-act";
import {ILoginAPIBody, IUser} from "modules/types";

export const loginAction = createAction<ILoginAPIBody>();
export const loginActionSuccess = createAction<IUser>();

export const registerAction = createAction();

export const setIsRegisteredAction = createAction<boolean>();
export const setIsLoggedInAction = createAction<boolean>();
export const setMMIDAction = createAction<string>();
