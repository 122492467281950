import {isString} from "lodash";
import {SagaIterator} from "redux-saga";
import {ISagaAction} from "modules/types";
import {ApiError} from "modules/utils/Api/ApiError";
// import {User} from "modules/utils/User";
// import {getIsUserAuthed} from "modules/selectors/user";
import {call, put} from "typed-redux-saga";
import {setIsLoggedInAction, setIsRegisteredAction, showGlobalError} from "modules/actions";
import {INormalizedErrors} from "modules/types/api";

type TPayload = INormalizedErrors;

const onError = {
	*[ApiError.AUTH_REQUIRED](error: TPayload): SagaIterator {
		// if (yield* select(getIsUserAuthed)) {
		// 	User.CLEAR();
		// 	// localStorage.removeItem("codeForJoin");
		// 	// yield* put(clearUser());
		// }

		yield* call(onError.DEFAULT, error);
	},
	*[ApiError.USER_NOT_REGISTERED](error: TPayload): SagaIterator {
		yield* put(setIsLoggedInAction(true));
		yield* put(setIsRegisteredAction(false));
	},
	// eslint-disable-next-line require-yield
	DEFAULT: function* (payload: TPayload) {
		if (isString(payload)) {
			throw new Error(payload);
		}

		if (isString(payload.message)) {
			throw new Error(payload.message);
		}
	},
};

export const errorsManagerSaga = function* ({payload}: ISagaAction<TPayload>): SagaIterator {
	const errorCode = payload.code ?? 0;

	try {
		const onErrorHandler = onError[errorCode] ?? onError.DEFAULT;
		yield* call(onErrorHandler, payload);
	} catch (err) {
		yield* put(
			showGlobalError({
				message: payload.message,
				code: errorCode || 0,
			})
		);
	}
};
