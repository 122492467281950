import {ILoginAPIBody, ISagaAction} from "modules/types";
import {SagaIterator} from "redux-saga";
import {call, put, select} from "typed-redux-saga";
import {globalError, loginActionSuccess, setGlobalPreloaderState, setIsRegisteredAction} from "modules/actions";
import {logSagaError} from "modules/utils/helpers/common";
import {Api} from "modules/utils/Api";
import {getMMID} from "modules/selectors/auth";

export const loginSaga = function* (action: ISagaAction<ILoginAPIBody>): SagaIterator {
	const {mmid} = action.payload;

	try {
		yield* put(setGlobalPreloaderState(true));
		const {success} = yield* call(Api.Auth.login, {mmid});
		yield* put(loginActionSuccess(success.user));
	} catch (e) {
		logSagaError("loginSaga", action, e, {mmid});
		yield* put(globalError(e));
	}
	yield* put(setGlobalPreloaderState(false));
};

export const registerSaga = function* (): SagaIterator {
	const mmid = yield* select(getMMID);
	try {
		yield* put(setGlobalPreloaderState(true));
		const {success} = yield* call(Api.Auth.register, {mmid});
		yield* put(loginActionSuccess(success.user));
		yield* put(setIsRegisteredAction(true));
	} catch (e) {
		logSagaError("registerSaga", "none", e, {mmid});
		yield* put(globalError(e));
	}
	yield* put(setGlobalPreloaderState(false));
};
