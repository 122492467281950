import React from "react";
import styled from "styled-components";
import {BaseContainer} from "components/Layout";
import jerseyLogo from "assets/img/jersey-logo.svg";

const FooterWrapper = styled.footer`
	background: #fff;
	padding: 15px 0;
	border-top: 1px solid #e5e5e5;
`;
const Logo = styled.img`
	max-width: 62px;
	margin: 0 auto;
`;
const LegalText = styled.div`
	font-size: 10px;
	line-height: 13px;
	color: #000;
	text-transform: uppercase;
	text-align: center;
	margin-top: 10px;
`;

export const Footer: React.FC = () => {
	return (
		<FooterWrapper>
			<BaseContainer direction={"column"} align={"center"} justify={"center"}>
				<Logo src={jerseyLogo} />
				<LegalText>
					"Jersey Mike’s Subs," "Jersey Mike’s," and "Mike’s Way" are registered trademarks of A Sub Above,
					LLC. "Lay's" is a registered
					<br /> trademark of Frito-Lay North America, Inc. "Pepsi" is a registered trademark of PepsiCo, Inc.
					Patents 6,384,850, 6,871,325, 6,982,733 and 8,146,077
				</LegalText>
			</BaseContainer>
		</FooterWrapper>
	);
};
