import {call, put, select} from "typed-redux-saga";
import {Api} from "modules/utils/Api";
import {fetchLeaderboardSuccessAction, globalError, setGlobalPreloaderState} from "modules/actions";
import {logSagaError} from "modules/utils/helpers/common";
import {getMMID} from "modules/selectors/auth";
// import {fetchContestsSuccess} from "modules/actions";

export const fetchLeaderboardSaga = function* () {
	const mmid = yield* select(getMMID);
	try {
		yield* put(setGlobalPreloaderState(true));
		const {success} = yield* call(Api.Leaderboard.get);
		yield put(fetchLeaderboardSuccessAction(success.leaderboard.schools));
	} catch (e) {
		logSagaError("fetchLeaderboardSaga", "none", e, {mmid});
		yield* put(globalError(e));
	}
	yield* put(setGlobalPreloaderState(false));
};
