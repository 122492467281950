import {call, put, select} from "typed-redux-saga";
import {Api} from "modules/utils/Api";
import {fetchStaticDataSuccess, globalError, sendContactDataSuccess} from "modules/actions";
import {ISagaAction} from "modules/types";
import {ISendContactData} from "modules/types/static";
import {logSagaError} from "modules/utils/helpers/common";
import {getMMID} from "modules/selectors/auth";

export const fetchStaticDataSaga = function* () {
	try {
		const staticData = yield* call(Api.JSON.static);
		const faqsData = yield* call(Api.JSON.faqs);
		yield put(
			fetchStaticDataSuccess({
				...staticData,
				faq: faqsData,
			})
		);
	} catch (e) {
		console.log(e);
	}
};

export const sendContactDataSaga = function* ({payload}: ISagaAction<ISendContactData>) {
	const mmid = yield* select(getMMID);
	try {
		yield* call(Api.Help.contact, payload);
		yield put(sendContactDataSuccess());
	} catch (e) {
		logSagaError("fetchLeaderboardSaga", "none", e, {mmid});
		yield* put(globalError(e));
	}
};
