import {Action} from "redux";
import * as Sentry from "@sentry/browser";

export const logSagaError = (sagaName: string, action: Action | "none", errors: Array<unknown>, extra?: unknown) => {
	if (Sentry) {
		// Add all the extra context
		Sentry.withScope((scope) => {
			scope.setTag("saga-error", sagaName);
			scope.setExtra("action", action);
			scope.setExtra("extra", extra);
			scope.setLevel(Sentry.Severity.Warning);
			Sentry.captureException(errors);
		});
	}
};
